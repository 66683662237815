var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { style: { "max-width": _vm.maxWidth } },
    [
      _c(
        "v-expand-x-transition",
        [
          _c(
            "v-sheet",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.opened,
                  expression: "opened",
                },
              ],
              class: {
                fixed: _vm.position === "fixed",
                sticky: _vm.position === "sticky",
              },
              attrs: {
                elevation: _vm.elevation,
                "max-width":
                  _vm.position === "fixed" ? _vm.maxWidth : undefined,
              },
            },
            [
              _c(
                "v-layout",
                { attrs: { column: "", "fill-height": "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dense: "", color: "transparent", flat: "" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.close } },
                        [_c("v-icon", [_vm._v("fa-light fa-xmark")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("ChatSection", { attrs: { instance: _vm.instance } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-fab-transition",
        [
          _c(
            "v-btn",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.opened,
                  expression: "!opened",
                },
              ],
              attrs: {
                fab: "",
                large: "",
                bottom: "",
                right: "",
                fixed: "",
                color: "amber darken-2",
              },
              on: { click: _vm.open },
            },
            [_c("v-icon", [_vm._v("fa-light fa-user-hair-buns")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }